<i18n>
{
  "ru": {
    "title": "Изменение клиента"
  },
  "he": {
    "title": "שינוי הלקוח"
  }
}
</i18n>

<template>
  <div>
    <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="1500"
      :color="snackbar.color"
      centered
    >
      <div class="text-center" v-html="snackbar.text"></div>
    </v-snackbar>

    <v-form v-model="valid">
      <v-row class="mb-5">
        <template v-for="(item, index) in propsType">
          <v-col
            cols="12"
            :key="`prop-item-type-${index}`"
          >
            <template v-if="item.type === 'radio'">
              <v-radio-group
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
              >
                <v-row>
                  <template v-for="(radio, i) in item.list">
                    <v-col
                      cols="auto"
                      :key="`radio-item-${item.code}-${i}`"
                    >
                      <v-radio
                        :label="radio.name"
                        :value="radio.id"
                        color="teal"
                      ></v-radio>
                    </v-col>
                  </template>
                </v-row>
              </v-radio-group>
            </template>
          </v-col>
        </template>

        <template v-for="(item, index) in propsInfo">
          <v-col
            cols="6"
            :key="`prop-item-info-${index}`"
          >
            <template v-if="item.type === 'switch'">
              <v-switch
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                color="teal"
                :false-value="item.falseValue"
                :true-value="item.trueValue"
              ></v-switch>
            </template>

            <template v-if="item.type === 'text'">
              <v-text-field
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
              ></v-text-field>
            </template>
          </v-col>
        </template>

        <template v-if="showCompanyProps">
          <template v-for="(item, index) in propsCompany">
            <v-col
              cols="6"
              :key="`prop-item-company-${index}`"
            >
              <template v-if="item.type === 'text'">
                <v-text-field
                  v-model="item.value"
                  :label="item.name"
                  :disabled="item.disabled"
                  :required="item.required"
                  :rules="item.rules"
                  color="teal"
                ></v-text-field>
              </template>
            </v-col>
          </template>
        </template>

        <v-col cols="12" class="py-0"></v-col>
        <template v-for="(item, index) in propsAddress">
          <v-col
            :cols="item.col"
            :key="`prop-item-address-${index}`"
          >
            <template v-if="item.type === 'text'">
              <v-text-field
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
              ></v-text-field>
            </template>
          </v-col>
        </template>

        <template v-for="(item, index) in propsComment">
          <v-col
            cols="12"
            :key="`prop-item-comment-${index}`"
          >
            <template v-if="item.type === 'textarea'">
              <v-textarea
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
              ></v-textarea>
            </template>
          </v-col>
        </template>

        <template v-if="$store.getters.isAdmin">
          <template v-for="(item, index) in propsDeposit">
            <v-col
              cols="6"
              :key="`prop-item-deposit-${index}`"
            >
              <template v-if="item.type === 'switch'">
                <v-switch
                  v-model="item.value"
                  :label="item.name"
                  :disabled="item.disabled"
                  color="teal"
                  :false-value="item.falseValue"
                  :true-value="item.trueValue"
                ></v-switch>
              </template>

              <template v-if="item.type === 'text'">
                <v-text-field
                  v-model.number="item.value"
                  :label="item.name"
                  :disabled="item.disabled"
                  :required="item.required"
                  :rules="item.rules"
                  color="teal"
                ></v-text-field>
              </template>
            </v-col>
          </template>
        </template>
      </v-row>

      <v-row class="mb-5">
        <v-col
          cols="4"
        >
          <v-text-field
            :value="sumOrdersNoPay"
            :label="$t('sumOrdersNoPay')"
            disabled
            color="teal"
          ></v-text-field>
        </v-col>

        <!-- <v-col
          cols="4"
        >
          <v-text-field
            :value="clientOrdersSum"
            label="Общая сумма заказов"
            disabled
            color="teal"
          ></v-text-field>
        </v-col>

        <template v-if="depositChecked">
          <v-col
            cols="4"
          >
            <v-text-field
              :value="clientOrdersDepositSum"
              label="Общая сумма заказов c депозитом"
              disabled
              color="teal"
            ></v-text-field>
          </v-col>

          <v-col
            cols="4"
          >
            <v-text-field
              :value="diffOrdersDepositSumDepositSum"
              label="Остаток депозита"
              disabled
              color="teal"
            ></v-text-field>
          </v-col>
        </template> -->
      </v-row>

      <v-row class="mt-5">
        <v-col
          cols="auto"
        >
          <v-btn
            color="teal"
            dark
            :loading="btnLoading"
            @click="save()"
          >{{ $t('btn.save') }}</v-btn>
        </v-col>

        <v-col
          cols="auto"
        >
          <v-btn
            color="grey lighten-5"
            to="/clients/"
          >{{ $t('btn.cancel') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'ClientEdit',
  data: (vm) => ({
    valid: false,
    btnLoading: false,
    snackbar: {},
    ordersNoPay: [],
    props: [
      {
        name: vm.$t('props.UF_TYPE'),
        code: 'UF_TYPE',
        type: 'radio',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        list: [
          {
            id: 1,
            name: vm.$t('clientType.entity'),
          },
          {
            id: 2,
            name: vm.$t('clientType.individual'),
          },
        ],
        group: 'type',
      },
      {
        name: vm.$t('props.UF_NAME'),
        code: 'UF_NAME',
        type: 'text',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'info',
      },
      {
        name: vm.$t('props.UF_ACTIVE'),
        code: 'UF_ACTIVE',
        type: 'switch',
        disabled: false,
        required: false,
        rules: [],
        value: '1',
        group: 'info',
        falseValue: '0',
        trueValue: '1',
      },
      {
        name: vm.$t('props.UF_PHONE'),
        code: 'UF_PHONE',
        type: 'text',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'info',
      },
      {
        name: vm.$t('props.UF_PHONE_NOTICE'),
        code: 'UF_PHONE_NOTICE',
        type: 'text',
        disabled: false,
        required: false,
        rules: [],
        value: '',
        group: 'info',
      },
      {
        name: vm.$t('props.UF_CONTACT_NAME'),
        code: 'UF_CONTACT_NAME',
        type: 'text',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'company',
      },
      {
        name: vm.$t('props.UF_CONTACT_PHONE'),
        code: 'UF_CONTACT_PHONE',
        type: 'text',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'company',
      },
      {
        name: vm.$t('props.UF_COMPANY_ID'),
        code: 'UF_COMPANY_ID',
        type: 'text',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'company',
      },
      {
        name: vm.$t('props.UF_ADDRESS'),
        code: 'UF_ADDRESS',
        type: 'text',
        disabled: false,
        required: false,
        rules: [],
        value: '',
        group: 'address',
        col: 6,
      },
      {
        name: vm.$t('props.UF_STREET'),
        code: 'UF_STREET',
        type: 'text',
        disabled: false,
        required: false,
        rules: [],
        value: '',
        group: 'address',
        col: 6,
      },
      {
        name: vm.$t('props.UF_HOUSE'),
        code: 'UF_HOUSE',
        type: 'text',
        disabled: false,
        required: false,
        rules: [],
        value: '',
        group: 'address',
        col: 4,
      },
      {
        name: vm.$t('props.UF_FLOOR'),
        code: 'UF_FLOOR',
        type: 'text',
        disabled: false,
        required: false,
        rules: [],
        value: '',
        group: 'address',
        col: 4,
      },
      {
        name: vm.$t('props.UF_FLAT'),
        code: 'UF_FLAT',
        type: 'text',
        disabled: false,
        required: false,
        rules: [],
        value: '',
        group: 'address',
        col: 4,
      },
      {
        name: vm.$t('props.UF_COMMENT'),
        code: 'UF_COMMENT',
        type: 'textarea',
        disabled: false,
        required: false,
        rules: [],
        value: '',
        group: 'comment',
      },
      {
        name: vm.$t('props.UF_DEPOSIT'),
        code: 'UF_DEPOSIT',
        type: 'switch',
        disabled: false,
        required: false,
        rules: [],
        value: '0',
        group: 'deposit',
        falseValue: '0',
        trueValue: '1',
      },
      // {
      //   name: 'Сумма депозита',
      //   code: 'UF_DEPOSIT_SUM',
      //   type: 'text',
      //   disabled: false,
      //   required: true,
      //   rules: [
      //     (v) => /^(0|-?[1-9]\d*)$/.test(v) || 'Должно быть число',
      //   ],
      //   value: 0,
      //   group: 'deposit',
      // },
    ],
  }),
  computed: {
    id() {
      return +this.$route.params.id;
    },
    propsType() {
      return this.props.filter((item) => item.group === 'type');
    },
    propsInfo() {
      return this.props.filter((item) => item.group === 'info');
    },
    propsCompany() {
      return this.props.filter((item) => item.group === 'company');
    },
    propsAddress() {
      return this.props.filter((item) => item.group === 'address');
    },
    propsComment() {
      return this.props.filter((item) => item.group === 'comment');
    },
    propsDeposit() {
      return this.props.filter((item) => item.group === 'deposit');
    },
    showCompanyProps() {
      const findProp = this.props.find((prop) => prop.code === 'UF_TYPE');

      return (findProp && findProp.value === 1);
    },
    depositChecked() {
      const findProp = this.props.find((prop) => prop.code === 'UF_DEPOSIT');

      if (findProp) {
        return findProp.value;
      }

      return false;
    },
    depositSum() {
      const findProp = this.props.find((prop) => prop.code === 'UF_DEPOSIT_SUM');

      if (findProp) {
        return findProp.value;
      }

      return 0;
    },
    sumOrdersNoPay() {
      return this.ordersNoPay.reduce((total, item) => {
        const sum = item.UF_SUM + item.UF_DELIVERY_SUM + item.UF_RECEIPTS_SUM - item.UF_PAID_SUM;
        return total + sum;
      }, 0);
    },
  },
  methods: {
    getProps() {
      this.$store.dispatch('getClient', this.id).then((response) => {
        const { result } = response.data.data;

        if (result) {
          this.props = this.props.map((item) => {
            const prop = item;

            prop.value = result[prop.code];

            return prop;
          });
        }
      });
    },
    getOrdersNoPay() {
      const params = {
        filter: {
          UF_CLIENT: this.id,
          UF_PAID: [3, 4],
        },
        select: ['UF_SUM', 'UF_DELIVERY_SUM', 'UF_RECEIPTS_SUM', 'UF_PAID_SUM'],
      };

      this.$store.dispatch('getOrders', { params }).then((response) => {
        this.ordersNoPay = response.data.data.result;
      });
    },
    save() {
      if (this.valid) {
        this.btnLoading = true;
        const params = new FormData();
        const props = {};

        this.props.forEach((prop) => {
          props[prop.code] = prop.value;
        });

        params.append('id', this.id);
        params.append('params', JSON.stringify(props));

        this.$store.dispatch('updateClient', params).then((response) => {
          const { success } = response.data.data;

          this.btnLoading = false;
          this.snackbar = {
            show: true,
            color: (success) ? 'green darken-3' : 'red darken-3',
            text: response.data.data.message,
          };
        });
      } else {
        this.snackbar = {
          show: true,
          color: 'red darken-3',
          text: this.$t('errorEmptyProps'),
        };
      }
    },
  },
  created() {
    this.getProps();
    this.getOrdersNoPay();
  },
};
</script>

<style lang="scss">
  .v-input--radio-group {
    text-align: initial;
  }
</style>
